
import HomeDash from './home/dash.config.json';
import UserTable from './user/table.config.json';
import UserForm from './user/form.config.json';
import UserView from './user/view.config.json';
import StaffTable from './staff/table.config.json';
import StaffForm from './staff/form.config.json';
import StaffView from './staff/view.config.json';
import AdminForm from './admin/form.config.json';
import ShopTable from './shop/table.config.json';
import ShopForm from './shop/form.config.json';
import ShopView from './shop/view.config.json';
import MemberTable from './member/table.config.json';
import MemberForm from './member/form.config.json';
import MemberView from './member/view.config.json';
import MemberPackageTable from './memberPackage/table.config.json';
import MemberPackageForm from './memberPackage/form.config.json';
import MemberPackageView from './memberPackage/view.config.json';
import PackageTable from './package/table.config.json';
import PackageForm from './package/form.config.json';
import PackageView from './package/view.config.json';
import PurchaseForm from './purchase/form.config.json';
import PurchaseTable from './purchase/table.config.json';
import PurchaseView from './purchase/view.config.json';
import Report from './report/report.config.json';
import IncomeForm from './income/form.config.json';
import IncomeTable from './income/table.config.json';
import IncomeView from './income/view.config.json';
import OutcomeForm from './outcome/form.config.json';
import OutcomeTable from './outcome/table.config.json';
import OutcomeView from './outcome/view.config.json';
import StyleForm from './style/form.config.json';
import StyleTable from './style/table.config.json';
import StyleView from './style/view.config.json';
import ClassTypeForm from './classType/form.config.json';
import ClassTypeTable from './classType/table.config.json';
import ClassTypeView from './classType/view.config.json';
import LevelForm from './level/form.config.json';
import LevelTable from './level/table.config.json';
import LevelView from './level/view.config.json';
import ClassInfoForm from './classInfo/form.config.json';
import ClassInfoTable from './classInfo/table.config.json';
import ClassInfoView from './classInfo/view.config.json';

const pages = {
  home: {
    dash: HomeDash,
  },
  profile: {
    edit: AdminForm,
  },
  user: {
    table: UserTable,
    edit: UserForm,
    view: UserView,
  },
  staff: {
    table: StaffTable,
    edit: StaffForm,
    view: StaffView,
  },
  shop: {
    table: ShopTable,
    edit: ShopForm,
    view: ShopView,
  },
  purchase: {
    edit: PurchaseForm,
    table: PurchaseTable,
    view: PurchaseView,
  },
  report1: {
    report: Report,
  },
  report2: {
    report: Report,
  },
  report3: {
    report: Report,
  },
  package: {
    table: PackageTable,
    edit: PackageForm,
    view: PackageView,
  },
  member: {
    table: MemberTable,
    edit: MemberForm,
    view: MemberView,
  },
  memberPackage: {
    table: MemberPackageTable,
    edit: MemberPackageForm,
    view: MemberPackageView,
  },
  income: {
    edit: IncomeForm,
    table: IncomeTable,
    view: IncomeView,
  },
  outcome: {
    edit: OutcomeForm,
    table: OutcomeTable,
    view: OutcomeView,
  },
  style: {
    edit: StyleForm,
    table: StyleTable,
    view: StyleView,
  },
  classType: {
    edit: ClassTypeForm,
    table: ClassTypeTable,
    view: ClassTypeView,
  },
  level: {
    edit: LevelForm,
    table: LevelTable,
    view: LevelView,
  },
  classInfo: {
    edit: ClassInfoForm,
    table: ClassInfoTable,
    view: ClassInfoView,
  },
};

export default pages;

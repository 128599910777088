import {
  post, get, put, deleted, upload, getRootUrl,
} from '../commons/request';

const list = (model, params) => get(model, params);
const find = (model, id) => get(`${model}/${id}`);
const create = (model, params) => post(`${model}`, params);
const update = (model, id, params) => put(`${model}`, id, params);
const remove = (model, id) => deleted(`${model}`, id);
const uploadFile = (model, file) => upload(`${model}`, file);

const Model = {
  list,
  find,
  create,
  update,
  remove,
  uploadFile,
  getRootUrl,
};

export default Model;

/* eslint-disable no-param-reassign */
import {
  map, cloneDeep, replace, each, includes, isPlainObject, get, set, merge,
  isArray, toUpper, findKey, split, first, drop, join, isEqual, isNaN, indexOf,
  isString,
  isEmpty,
} from 'lodash';
import moment from 'moment';
import Model from './model';
import Global from '../commons/Global';

const getKey = (val) => replace(val, 'model.', '');
const getOptionKey = (path) => join(drop(split(path, '.')), '.');
const getObjectKey = (path) => first(split(path, '.'));

const valueMap = (item, row, val, key, options) => {
  if (!includes(val, 'model')) {
    return item;
  }
  const keyField = getKey(val);
  const value = get(row, keyField);

  // eslint-disable-next-line no-underscore-dangle
  if (isArray(value) && value.length && value[0]._id && (!options || !options[keyField])) {
    set(item, key, map(value, ({ _id }) => _id));
    return item;
  }
  if (!options || !options[keyField]) {
    if (value && Number.isNaN(value) && moment(value).isValid()) {
      set(item, key, moment(value));
      return item;
    }
    set(item, key, value);
    return item;
  }
  if (isArray(value) && options[keyField]
  && (isArray(options[keyField]) || isPlainObject(options[keyField]))) {
    const array = [];
    each(value, (v, i) => {
      if (v || v === options[keyField][i]) {
        array.push(options[keyField][i]);
      }
    });
    set(item, key, array);
    return item;
  }
  if (options[keyField] && (isArray(options[keyField]) || isPlainObject(options[keyField]))) {
    set(item, key, options[keyField][value || 0] || value);
  }

  return item;
};

const processContent = (template, item, row, options) => {
  each(template, (val, key) => {
    if (isPlainObject(val)) {
      processContent(val, item[key], row, options);
    } else {
      valueMap(item, row, val, key, options);
    }
  });
};

const processModel = (items, model, records) => {
  each(items, (item) => {
    if (item.value) {
      const modelKey = getKey(item.value);
      item.value = get(records, modelKey);
    }
    if (item.data) {
      const modelKey = getKey(item.data);
      item.data = get(records, modelKey);
    }
    if (item.items) {
      processModel(item.items, model, records);
    }
  });
};

const getOption = async (options, option, key) => {
  if (isPlainObject(option) || isArray(option)) {
    options[key] = option;
    return option;
  }
  const store = Global.getInstance().getStore();
  let params = {};
  if (store.shop) {
    params = {
      filter: {
        $or: [{
          shop: store.shop,
        }, {
          shop: null,
        }],
        deactivated: {
          $ne: true,
        },
      },
    };
  }
  console.debug((new Date()).toISOString(), 'getOptions', 'params:', params);
  const results = await Model.list(option, params);
  const keyField = getOptionKey(key);
  const optionMap = {};
  each(results, (item) => {
    const { _id } = item;
    optionMap[`${_id}`] = keyField === key ? item : get(item, keyField || 'name');
  });
  options[key] = optionMap;
  return optionMap;
};

const getOptions = async (content) => {
  console.debug((new Date()).toISOString(), 'getOptions', content.optionsMap);
  const options = {};
  const promises = map(content.optionsMap, (option, key) => getOption(options, option, key));
  return Promise.all(promises).then((values) => {
    content.options = options;
    console.debug((new Date()).toISOString(), 'getOptions', values, content.options);
    return content;
  });
};


const getModels = async (template, params) => {
  console.debug((new Date()).toISOString(), 'getModels', template.id, template.model);

  const store = Global.getInstance().getStore();
  let filter = {};
  if (store.shop) {
    filter = {
      filter: {
        $or: [{
          shop: store.shop,
        }, {
          shop: null,
        }],
      },
    };
  }
  console.debug((new Date()).toISOString(), 'getModels', 'params:', merge(params, filter));
  const results = await Model.list(template.model, params);
  if (!template.item) {
    processModel(template.items, template.model, results);
    return template;
  }
  return getOptions(template).then(async (content) => {
    content.items = [];
    content.data = [];
    each(!results.page ? results : results.records, (row) => {
      const item = cloneDeep(content.item);
      processContent(content.item, item, row, content.options);
      content.items.push(item);
    });
    if (results.page) {
      content.page = results.page;
      content.pageSize = results.pageSize;
      content.sort = results.sort;
      content.total = results.total;
    }
    console.debug((new Date()).toISOString(), 'getModels', content);
    return content;
  });
};

const getDash = async (template, params) => {
  const { shop } = Global.getInstance().getStore();
  console.debug((new Date()).toISOString(), 'getDash', template.id, template.model, shop);
  if (!shop) {
    return getModels(template, { ...params });
  }
  return getModels(template, { ...params, shop });
};

const getModel = async (content, { id }) => {
  console.debug((new Date()).toISOString(), 'getModel', content.id, content.model);
  const result = await Model.find(content.model, id);
  content.data = {};
  const item = cloneDeep(content.item);
  // processContent(content.item, item, result, content.options);
  each(result, (value, keyField) => {
    const title = findKey(item, (model) => isEqual(getObjectKey(getKey(model)), keyField));
    if (!title) {
      return true;
    }
    if (value === undefined || value === null) {
      return true;
    }
    const { _id } = value;
    if ((!content.options || !content.options[keyField]) && !_id) {
      if (isArray(value)) {
        // eslint-disable-next-line no-underscore-dangle
        const array = map(value, (v) => (isPlainObject(v) ? v._id || v : v));
        item[title] = array;
      // eslint-disable-next-line no-restricted-globals
      } else if (value && !isNaN(+value)) {
        item[title] = +value;
      } else if (value && moment(value).isValid()) {
        item[title] = moment(value);
      } else if (value && moment(value, 'h:mm A').isValid() && title.includes('TIME')) {
        item[title] = moment(value, 'h:mm A');
      } else {
        item[title] = value;
      }
    } else if ((isArray(content.options[keyField])
      || isPlainObject(content.options[keyField])) && !_id) {
      if (isArray(value)) {
        const array = [];
        each(value, (v, i) => {
          if (isPlainObject(v)) {
            // eslint-disable-next-line no-underscore-dangle
            array.push(v._id);
          } else if (v || v === content.options[keyField][i]) {
            array.push(content.options[keyField][i]);
          }
        });
        item[title] = array;
      } else {
        item[title] = content.options[keyField][value];
      }
    } else {
      item[title] = _id || value;
    }

    return true;
  });
  content.data = item;

  each(content.data, (val, key) => {
    if (isString(val) && val.includes('model.')) {
      if (val.includes('deactivated') || val.includes('deleted')) {
        content.data[key] = false;
        return true;
      }
      content.data[key] = undefined;
    }
    return true;
  });

  content.viewData = {};
  const viewItem = cloneDeep(content.item);
  processContent(content.item, viewItem, result, content.options);
  content.viewData = viewItem;

  console.debug((new Date()).toISOString(), 'getModel', result, content.data);
  return content;
};

const setModel = async (content, fieldModel) => {
  const model = {};
  console.debug((new Date()).toISOString(), 'setModel', content);
  each(content.item, (path, key) => {
    if (!content.options[getKey(path)]) {
      if (get(fieldModel, key) !== path) {
        set(model, path, get(fieldModel, key));
      }
      return true;
    }
    if (isArray(content.options[getKey(path)])) {
      if (!isArray(get(fieldModel, key))) {
        const value = isNaN(+get(fieldModel, key))
          ? indexOf(content.options[getKey(path)], get(fieldModel, key))
          : get(fieldModel, key);
        set(model, `model.${getObjectKey(getKey(path))}`, value);
        return true;
      }
      const array = map(content.options[getKey(path)],
        (option) => includes(get(fieldModel, key), option));
      set(model, path, array);
      return true;
    }
    if (isPlainObject(content.options[getKey(path)])) {
      if (!isArray(get(fieldModel, key))) {
        set(model, `model.${getObjectKey(getKey(path))}`, get(fieldModel, key));
        return true;
      }
      set(model, `model.${getObjectKey(getKey(path))}`, get(fieldModel, key));
    }
    return true;
  });
  console.debug((new Date()).toISOString(), 'setModel', model.model);
  const { _id, ...updateFields } = model.model;
  const { shop } = Global.getInstance().getStore();
  if (shop) {
    updateFields.shop = shop;
  }
  if (!_id) {
    const result = await Model.create(content.model, updateFields);
    return result;
  }
  const result = await Model.update(content.model, _id, updateFields);
  return result;
};

const getFile = async (id) => {
  if (!id) {
    return '';
  }
  const result = await Model.find('file', id);

  const { file } = result;
  console.debug((new Date()).toISOString(), 'getFile', file);
  if (file) {
    return `data:${file.mimetype};base64,${file.data}`;
  }
  return '';
};

const getMultipleFiles = async (_id) => {
  const url = await Model.find('file/base64', _id);
  return { _id, uid: _id, url };
};

const getFiles = async (ids) => {
  const promises = map(ids, (_id) => getMultipleFiles(_id));
  return Promise.all(promises).then((values) => {
    console.debug((new Date()).toISOString(), 'getFiles', values);
    return values;
  });
};

const getImage = async (id) => {
  if (!id) {
    return '';
  }
  return Model.find('file/base64', id);
};

const uploadFile = async (file) => {
  if (!file) {
    return undefined;
  }
  const result = await Model.uploadFile('file', file);
  console.debug((new Date()).toISOString(), 'uploadFile', result);
  return result;
};

const getLabels = (name) => toUpper(name);

const createRowActions = (actions, rowId) => map(actions, (action) => ({
  action, label: getLabels(action), id: `${action}|${rowId}`, rowId,
}));
const Template = {
  processContent,
  getModels,
  createRowActions,
  getFile,
  getFiles,
  getModel,
  setModel,
  uploadFile,
  getOptions,
  getDash,
  getImage,
};

export default Template;

/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';

const Async = (props) => {
  const { request, component } = props;
  const [asyncComponent, setAsyncComponent] = useState();
  const componentList = {
    Avatar,
  };
  useEffect(() => {
    let isSubscribed = true;
    request.then((result) => {
      if (isSubscribed) {
        const TagName = componentList[component];
        setAsyncComponent(<TagName src={result} />);
      }
    });
    return () => { isSubscribed = false; };
  }, [request]);
  return (
    <div>
      {asyncComponent}
    </div>
  );
};

export default Async;

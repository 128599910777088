import axios from 'axios';
import { join } from 'lodash';
import moment from 'moment';
import Config from '../config.json';

export function getRootUrl() {
  return `${Config.host}:${Config.port}/${Config.apiPath}`;
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(`${getRootUrl()}/${url}`, params).then(({ data }) => { if (data) { resolve(data); } else { reject(); } }).catch((error) => {
      reject(((error || {}).response || {}).data || ((error || {}).data || {}).details);
    });
  });
}

export function get(url, params) {
  const {
    page, pageSize, sort, sortField, filter, shop, date,
  } = params || {};
  const query = [];

  if (filter) {
    query.push(`filter=${JSON.stringify(filter)}`);
  }
  if (page) {
    query.push(`page=${page}`);
  }
  if (pageSize) {
    query.push(`pageSize=${pageSize}`);
  }
  if (sort) {
    query.push(`sort=${sort}`);
  }
  if (sortField) {
    query.push(`sortField=${sortField}`);
  }
  const queryStr = join(query, '&') || '';
  let urlStr = shop ? `${getRootUrl()}/shop/${url}/${shop}` : `${getRootUrl()}/${url}`;
  if (url === 'dash') {
    return new Promise((resolve, reject) => {
      axios.get(`${urlStr}/${moment().format('YYYY-MM-DD')}`).then((res) => {
        const results = res.data;
        if (results) resolve(results);
        else reject();
      }).catch((error) => {
        reject(error.response.data.details);
      });
    });
  }
  if (!date && queryStr) {
    urlStr = `${urlStr}?${queryStr}`;
  }
  if (date && queryStr) {
    urlStr = `${urlStr}/${date}?${queryStr}`;
  }
  if (date && !queryStr) {
    urlStr = `${urlStr}/${date}`;
  }
  return new Promise((resolve, reject) => {
    axios.get(urlStr).then((res) => {
      const results = res.data;
      if (results) resolve(results);
      else reject();
    }).catch((error) => {
      reject(error.response.data.details);
    });
  });
}

export function put(url, id, row) {
  return new Promise((resolve, reject) => {
    axios.put(`${getRootUrl()}/${url}/${id}`, row).then((res) => {
      const results = res.data;
      if (res.status === 200) resolve(results);
      else reject();
    }).catch((error) => {
      reject(error.response.data.details);
    });
  });
}

export function deleted(url, id) {
  return new Promise((resolve, reject) => {
    axios.delete(`${getRootUrl()}/${url}/${id}`).then((res) => {
      const results = res.data;
      if (res.status === 200) resolve(results);
      else reject();
    }).catch((error) => {
      reject(error.response.data.details);
    });
  });
}

export function upload(url, file) {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  console.log(file, formData);
  return new Promise((resolve, reject) => {
    axios.post(`${getRootUrl()}/${url}`, formData, config).then((res) => {
      const results = res.data;
      if (results) resolve(results);
      else reject();
    }).catch((error) => {
      // reject(error.respose.data.details);
      console.log(error);
      reject();
    });
  });
}
